import React from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

function FindIdTabs({tab}) 
{
    return (
        <div id="gpHead" className="d-flex flex-column align-items-left" role="banner">
            <h1 className="cfs-24 text-primary mt-3 mb-1">아이디 찾기</h1>
            { tab && (
            <Breadcrumb className="d-flex flex-column align-items-center cfs-17">
                <Breadcrumb.Item href="/findId">아이디 찾기</Breadcrumb.Item>
                {tab==='email' ? (
                <Breadcrumb.Item active>이메일로 찾기</Breadcrumb.Item>
                ):(
                <Breadcrumb.Item active>휴대폰으로 찾기</Breadcrumb.Item>
                )}
            </Breadcrumb>
            )}
        </div>
    );
}
export default FindIdTabs;