import React, { useState, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import ProgressModal from '../modal/ProgressModal';
import ModalPortal from '../../ModalPortal';
import axios from "axios";
import { ApiContext } from '../../Context';

function ModifyPrivacyHistory() {
    const context = useContext(ApiContext);
    const USER_API = context.url;
    
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
    const [modalProgress, setModalProgress] = useState(false);
    const intialValues = { email_auth_date: "", phone_auth_date: ""}; 
    const [formValues, setFormValues] = useState(intialValues);

    const listAuth = async() => {
        setModalProgress(true);
        //let USER_API = "https://userapi.godpeople.com/";
        const response = await axios.post(USER_API,{
            task: 'listAuth',
        },{ withCredentials: true });
        
        if(response.data.success===true){
            setFormValues({ email_auth_date: response.data.email_auth_date, phone_auth_date: response.data.phone_auth_date });
            setModalProgress(false);
        }else{
            setModalProgress(false);
        }
    }

    function handleShow() {
      setFullscreen(true);
      setShow(true);
      listAuth();
    }
  
    return (
      <>
        <div className="col-12 mb-2 d-flex bg-light px-3 py-1 rounded-1" onClick={() => handleShow()} role="button">
            <div className="py-2 col-10"> 
            <span className="d-block me-2 w-100 text-dialog-title">
                개인정보 이용내역
            </span>
            </div>
            <div className="py-2 col-2 text-end"><img src="/img/mic_bl.png" alt="ic_arrow" className="ic_arrow" /></div>
        </div>
       
        <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)} aria-labelledby="contained-modal-title-vcenter" dialogClassName="modal-50w" centered>
          <Modal.Header closeButton>
            <Modal.Title className="cfs-18">개인정보 이용내역</Modal.Title>
          </Modal.Header>
          <Modal.Body>
                <table className="table table-bordered table-area">
                <tbody>
                <tr>
                    <th scope="row" className="cfs-13">구분</th>
                    <td className="cfs-13 text-secondary">기본 (필수)</td>
                </tr>
                <tr>
                    <th scope="row" className="cfs-13 align-middle">개인정보항목</th>
                    <td className="cfs-13 text-secondary"><strong className="title1">회원가입</strong>아이디, 비밀번호, 이름, 생년월일, 메일주소, 휴대폰번호</td>
                </tr>
                <tr>
                    <th scope="row" className="cfs-13">동의일</th>
                    <td className="cfs-13 text-secondary">가입일</td>      
                </tr>
                </tbody>
                </table>

                <table className="table table-bordered table-area">
                <tbody>
                <tr>
                    <th scope="row" className="cfs-13">구분</th>
                    <td className="cfs-13 text-secondary">자동생성정보</td>
                </tr>
                <tr>
                    <th scope="row" className="cfs-13 align-middle">개인정보항목</th>
                    <td className="cfs-13 text-secondary">- 서비스 이용 과정이나 사업 처리 과정에서 자동 생성 및 수집될 수 있는 정보<br />- IP Address, 쿠키, 서비스 이용 기록</td>
                </tr>
                <tr>
                    <th scope="row" className="cfs-13">동의일</th>
                    <td className="cfs-13 text-secondary">가입일</td>      
                </tr>
                </tbody>
                </table>

                { formValues.phone_auth_date &&
                    <>
                    <table className="table table-bordered table-area">
                    <tbody>
                    <tr>
                        <th scope="row" className="cfs-13">구분</th>
                        <td className="cfs-13 text-secondary">휴대폰 번호 인증</td>
                    </tr>
                    <tr>
                        <th scope="row" className="cfs-13 align-middle">개인정보항목</th>
                        <td className="cfs-13 text-secondary">휴대 전화번호</td>
                    </tr>
                    <tr>
                        <th scope="row" className="cfs-13">동의일</th>
                        <td className="cfs-13 text-secondary">{formValues.phone_auth_date}</td>      
                    </tr>
                    </tbody>
                    </table>
                    </>
                }

                { formValues.email_auth_date &&
                    <>
                    <table className="table table-bordered table-area">
                    <tbody>
                    <tr>
                        <th scope="row" className="cfs-13">구분</th>
                        <td className="cfs-13 text-secondary">이메일 인증</td>
                    </tr>
                    <tr>
                        <th scope="row" className="cfs-13 align-middle">개인정보항목</th>
                        <td className="cfs-13 text-secondary">이메일 주소</td>
                    </tr>
                    <tr>
                        <th scope="row" className="cfs-13">동의일</th>
                        <td className="cfs-13 text-secondary">{formValues.email_auth_date}</td>      
                    </tr>
                    </tbody>
                    </table>
                    </>
                }

                <table className="table table-bordered table-area">
                <tbody>
                <tr>
                    <th scope="row" className="cfs-13">구분</th>
                    <td className="cfs-13 text-secondary">고객센터</td>
                </tr>
                <tr>
                    <th scope="row" className="cfs-13 align-middle">개인정보항목</th>
                    <td className="cfs-13 text-secondary"><strong className="title1">PC 고객센터</strong>- 이름, 아이디, 이메일, 휴대폰 번호<p></p><strong className="title1">갓피플성경 고객센터</strong>- 아이디, 휴대폰 번호, 디바이스 정보</td>
                </tr>
                <tr>
                    <th scope="row" className="cfs-13">동의일</th>
                    <td className="cfs-13 text-secondary">고객 응대를 위해 회원정보를 활용합니다.</td>      
                </tr>
                </tbody>
                </table>
          </Modal.Body>
        </Modal>

        { modalProgress && ( 
            <ModalPortal>
                <ProgressModal />
            </ModalPortal>
        )}
      </>
    );
  
}
export default ModifyPrivacyHistory;